<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getProductImagesQuery",
  
  watch: {
    productImages() {
      this.$store.commit('graphqlData', { item: 'productImages', result: this.productImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      slug: computed(() => store.state.data.productSlug),
      channel: computed(() => store.state.data.channel),
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
    });
    
    const getProductImagesQuery = gql`
      query MyQuery ($slug: String!) {
          item(where: {slug: $slug}) {
            images {
              fileName
               url(transformation: {document: {output: {format: webp}}, image: {resize: {height: 1000, width: 1000, fit: clip}}})
            }
            fullscreenImage {
                  url(transformation: {document: {output: {format: webp}}, image: {resize: {width: 1500}}})
            }
            fullscreenImageCredits
            more01Image {
                  url(transformation: {document: {output: {format: webp}}, image: {resize: {width: 1500}}})
            }
            more01ImageCredits
            more02Image {
                  url(transformation: {document: {output: {format: webp}}, image: {resize: {width: 1500}}})
            }
            more02ImageCredits
            related {
              images(first: 1) {
                url(transformation: {document: {output: {format: webp}}, image: {resize: {height: 1000, width: 1000}}})
              }
            }
          }
        }
    `;
    
    const { data: productImages } = useQuery({
      query: getProductImagesQuery,
      variables
    });
    
    return { productImages };
  },
}
</script>