<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getCommerceDataQuery",
  
  watch: {
    result2() {
      this.$store.commit('graphqlData', { item: 'result2', result: this.result2 });
     },
     isFetching() {
      this.$store.commit('graphqlData', { item: 'isFetching', result: this.isFetching });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      slug: computed(() => store.state.data.productSlug),
      channel: computed(() => store.state.data.channel),
    });
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only',
    });

    const getCommerceDataQuery = gql`
      query ($slug: String!, $channel: String!) {
        product(slug: $slug, channel: $channel) {
          id
          name
          slug
          variants {
                id
                name
                attributes(variantSelection: ALL){
                  attribute{
                    id
                    name
                  }
                  values{
                    id
                    name
                  }
                }
                quantityAvailable
                metadata {
                  key
                  value
                }
           			pricing{
                  price {
                    net {
                      amount
                    }
                    gross {
                      amount
                    }
                    tax {
                      amount
                    }
                }
              }
          }
        }
      }
    `;
    
    const { data: result2, isFetching } = useQuery({
      query: getCommerceDataQuery,
      variables
    });
    
    return { result2, isFetching };
  },
}
</script>