<template>
  <div class="font-stolzl min-h-screen overflow-hidden md:overflow-visible">

    <!-- IMAGE ZOOM BUTTONS-->
    <div id="imageButtons" v-if="zoomedIn && !zoomedInMobile" class="z-50 h-screen fixed inset-y-0 left-0 flex flex-col justify-center items-center ml-8">
      <div class="transition duration-500 ease-in-out w-16 h-16 bg-coolGray-900 flex flex-row justify-center items-center cursor-pointer group hover:bg-white hover:text-coolGray-900 border-2 border-coolGray-900" @click="up()">
        <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-6 w-6 text-white group-hover:text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </div>
      <div class="transition duration-500 ease-in-out w-16 h-16 bg-coolGray-900 flex flex-row justify-center items-center cursor-pointer group hover:bg-white hover:text-coolGray-900 border-2 border-coolGray-900" @click="down()">
        <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-6 w-6 text-white group-hover:text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
    <!-- IMAGE ZOOM BUTTONS-->

    <div id="anim">
    <div typeof="schema:Product" class="md:flex md:flex-row md:justify-center md:items-start md:w-screen min-h-screen">

      <!-- TOP // LEFT -->
      <div v-if="cms?.item && cmsImages?.item" id="imageContainer" class="h-55 md:h-auto w-screen relative top-0 z-0 md:static md:w-1/2 md:min-h-full overflow-hidden">
        <div id="space" class="md:h-16"></div>
        <div v-if="images.length > 1" class="absolute z-20 inset-x-0 bottom-8 flex flex-row justify-center items-center px-8 md:hidden">
          <div  v-for="(image,index) in images" :key="image">
            <div v-if="currentImageIndex == index" class="w-1.5 h-1.5 mx-2 rounded-full ring-1 ring-opacity-80 bg-coolGray-900 ring-coolGray-900"></div>
            <div v-else class="w-1.5 h-1.5 mx-2 rounded-full ring-1 ring-opacity-80 ring-coolGray-900"></div>
          </div>
        </div>
        <div id="slider" class="relative flex flex-row w-screen h-full md:h-auto md:w-full md:px-2 pb-2 md:flex-col cursor-zoom-in" :class="{'cursor-zoom-out' : zoomedIn}">
        <div v-for="(image,index) in images" :key="image" rel="schema:image" :resource="images[index].url" :id="index" class="flex-shrink-0 w-full h-full bg-local bg-center bg-no-repeat bg-contain md:min-h-full md:mb-2 md:h-screen md:bg-contain bg-coolGray-100" :style="{ backgroundImage: 'url(' + images[index].url + ')' }"
          @touchstart.passive="dragStart" @touchend.passive="dragEnd" @touchmove.passive="drag" @click="zoomIn()"></div>
        </div>
      </div>
      <!-- TOP // LEFT -->

      <!-- BOTTOM // RIGHT -->

      <!-- PRODUCT INFO-->
      <div v-if="!zoomedInMobile && cms?.item && cmsImages?.item" id="infoContainer" class="md:sticky w-full md:top-0 font-stolzl md:h-screen h-auto mt-5 md:mt-0 mb-5 relative bg-white md:w-1/2 md:bg-white md:z-10 md:flex md:flex-row md:justify-start md:items-center" rel="schema:brand" property="schema:name" content="rewhite">
        <div class="w-full px-8">
        <div class="md:h-full md:pl-6 md:pr-16">
          <div class="md:flex md:flex-col md:justify-center md:items-left md:h-full">
            <div class="flex flex-col md:flex-row justify-start items-start md:items-center">
              <div property="schema:name" :content="cms.item.name" class="font-bold text-3xl text-coolGray-900">{{cms.item.name}}</div>
              <div v-if="notify" class="hidden md:block font-bold text-3xl text-coolGray-900 pr-2">,</div>
              <div v-if="notify" class="font-bold text-3xl text-coolGray-900">{{commerce.product.variants[selectedSizeIndex].name}}</div>
            </div>

            <div v-if="!notify" property="schema:description" :content="cms.item.subtext" class=" text-base md:text-sm text-coolGray-700 font-light">{{cms.item.subtext}}</div>
            <div v-else-if="!submitted" class="font-light font-sm mt-1 text-coolGray-900">{{text.notify1}} <br> {{text.notify2}}</div>

            <!-- NOTIFY-->
            <form v-if="notify && !submitted" id="notify" @submit.prevent="submit" class="flex flex-col">

              <div class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="name && name !== ''" class="font-light text-coolGray-700 text-xs">{{text.name}}</div>
                </transition>
                <input id="name" name="name" v-model="name" type="text" :placeholder="text.name" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

              <div class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="email && email !== ''" class="font-light text-coolGray-700 text-xs">{{text.email}}</div>
                </transition>
                <input id="email" name="email" v-model="email" type="email" :placeholder="text.email" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

              <input type="submit" :value="text.notify" class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer hover:bg-white hover:text-coolGray-900 border-2 border-coolGray-900" />
            </form>

            <div v-if="submitted" class="font-light font-sm mt-1 text-coolGray-900">{{text.notify3}}</div>
            <!-- NOTIFY-->

              <!-- PRICING-->
              <div v-if="commerce?.product && !notify && !isFetching" rel="schema:offers">

                    <div  typeof="schema:Offer">
                      <div v-if="meta.some(e => e.key === 'originalPrice' + channel)" class="flex flex-row items-end" property="schema:priceCurrency" content="EUR">
                        <div v-if="channel == 'b2b'" class="font-normal text-lg mt-2 text-coolGray-900 mr-1">{{text.ek}}</div>
                        <div class="font-normal text-lg mt-2 text-rose-500" property="schema:price" :content="commerce.product.variants[selectedSizeIndex].pricing.price.gross.amount">{{formatPrice(commerce.product.variants[selectedSizeIndex].pricing.price.gross.amount)}}</div>
                        <div class="font-normal text-base md:text-sm line-through ml-2">{{formatPrice(meta.find( e => e.key == 'originalPrice' + channel ).value)}}</div>
                      </div>
                      <div v-else class="flex flex-row items-end" property="schema:priceCurrency" content="EUR">
                        <div v-if="channel == 'b2b'" class="font-normal text-lg mt-2 text-coolGray-900 mr-1">{{text.ek}}</div>
                        <div class="font-normal text-lg mt-2 text-coolGray-900" property="schema:price" :content="commerce.product.variants[selectedSizeIndex].pricing.price.gross.amount">{{formatPrice(commerce.product.variants[selectedSizeIndex].pricing.price.gross.amount)}}</div>
                      </div>
                      <div v-if="meta.some(e => e.key === 'UVP') && channel == 'b2b'" class="flex flex-row items-end">
                        <div class="font-light text-base md:text-sm text-coolGray-900 mr-1">{{text.uvp}}</div>
                        <div class="font-light text-base md:text-sm text-coolGray-900">{{formatPrice(meta.find( e => e.key == 'UVP' ).value)}}</div>
                      </div>
                    </div>

              </div>
              <!-- PRICING-->

            <!-- AVAILABILITY-->
            <div v-if="commerce?.product && !notify && !isFetching" property="schema:availability" content="https://schema.org/InStock">
              <div v-if="commerce.product.variants[selectedSizeIndex].quantityAvailable >= vars.[channel + 'lowOnStock']" class="flex flex-row items-center">
                <div class="w-2 h-2 rounded-full bg-green-400 mr-1.5"></div>
                <div class="text-base md:text-sm font-light text-coolGray-900">{{text.available}}</div>
                </div>
              <div v-else-if="commerce.product.variants[selectedSizeIndex].quantityAvailable < vars.[channel + 'lowOnStock'] && commerce.product.variants[selectedSizeIndex].quantityAvailable > 0" class="flex flex-row items-center">
                <div class="w-2 h-2 rounded-full bg-yellow-400 mr-1.5"></div>
                <div class="text-base md:text-sm font-light text-coolGray-900">{{text.lowStock}}</div>
                </div>
              <div v-else-if="commerce.product.variants[selectedSizeIndex].quantityAvailable <= vars.outOfStock" class="flex flex-row items-center">
                <div class="w-2 h-2 rounded-full bg-red-400 mr-1.5"></div>
                <div class="text-base md:text-sm font-light text-coolGray-900">{{text.soldOut}}</div>
                </div>
            </div>
            <!-- AVAILABILITY-->

            <!-- COLOR PICKER-->
            <div v-if="commerce?.product && !notify && !isFetching" class="flex flex-row justify-start items-center">
              <div v-for="color in colors" class="noSelect mr-4 mt-3" @click="switchColor(color)">
                <div class="transition duration-300 ease-out w-8 h-8 rounded-full cursor-pointer ring-1.5 ring-coolGray-100 ring-offset-3" :class="{'ring-coolGray-700' :  color == selectedColor}" :style="{ backgroundColor: colorCodes[color] }"></div>
              </div>
            </div>
            <!-- COLOR PICKER-->

            <div v-if="isFetching" class="animate-pulse px-8 py-4 bg-coolGray-100 flex flex-row justify-center items-center rounded-lg mt-3">
                <div class="text-base md:text-sm font-light text-coolGray-900">{{text.loading}}</div>
              </div>

            <div v-if="!notify">
              <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
                <div v-if="selectedText == 1 && cms?.item?.info1?.html" class="font-light text-base mt-5 text-coolGray-900" v-html="cms.item.info1.html"></div>
                <div v-else-if="selectedText == 2 && cms?.item?.info2?.html" class="font-light text-base mt-5 text-coolGray-900 flex-grow-1" v-html="cms.item.info2.html"></div>
                <div v-else-if="selectedText == 3 && cms?.item?.info3?.html" class="font-light text-base mt-5 text-coolGray-900" v-html="cms.item.info3.html"></div>
              </transition>
            </div>

            <!-- TEXT CATEGORIES-->
            <div v-if="!notify" class="flex md:flex-row flex-col md:justify-start md:items-center items-start mt-3">
              <div v-if="cms.item.info1Heading" class="flex flex-row justify-center items-center mr-5 group cursor-pointer mb-0.5" @click="selectedText = 1">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 1}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
                <div class="transition duration-300 ease-out ml-1 font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 1}">{{cms.item.info1Heading}}</div>
              </div>

              <div v-if="cms.item.info2Heading" class="flex flex-row justify-center items-center mr-5 group cursor-pointer mb-0.5" @click="selectedText = 2">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 2}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                </svg>
                <div class="transition duration-300 ease-out ml-1 font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 2}">{{cms.item.info2Heading}}</div>
              </div>

              <div v-if="cms.item.info3Heading" class="flex flex-row justify-center items-center mr-5 group cursor-pointer mb-0.5" @click="selectedText = 3">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-out h-5 md:h-4 w-5 md:w-4 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 3}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                </svg>
                <div class="transition duration-300 ease-out ml-1 font-light text-base md:text-sm mt-0.5 text-coolGray-400 group-hover:text-coolGray-900" :class="{'text-coolGray-900' : selectedText == 3}">{{cms.item.info3Heading}}</div>
              </div>
            </div>
            <!-- TEXT CATEGORIES-->

            <!-- BUY BUTTON DESKTOP-->
            <div class="hidden md:block md:w-full md:select-none">
              <div v-if="!notify" class="flex flex-row justify-between items-center mt-5">
                <div v-if="!isFetching && commerce?.product?.variants[0]?.name !== 'none'" class="transition duration-500 ease-in-out relative flex flex-row justify-center items-center w-1/3 bg-coolGray-900 h-16 mr-2 cursor-pointer group hover:bg-white"  @click="selectingSize = !selectingSize" @mouseleave="selectingSize = false">
                  <div class="absolute w-full h-full border-2 border-coolGray-900"></div>
                  <div v-if="commerce?.product" class="transition duration-500 ease-in-out font-stolzl font-light text-white flex flex-row justify-center items-center mr-2 group-hover:text-coolGray-900">{{sizeShortener(commerce.product.variants[selectedSizeIndex].name)}}</div>
                  <div v-if="selectingSize" class="absolute w-full bottom-16 -mb-1 bg-coolGray-900 -ml-1 -mr-1">
                    <div v-for="(option, index) in selectableVariants" class="relative text-white h-16 px-4 py-2 flex flex-row justify-center hover:bg-white border-2 border-coolGray-900 hover:text-coolGray-900" @click="setIndex(option.name)">
                     <div v-if="index == filteredSelectedSizeIndex" class="w-full flex flex-row justify-center items-center font-stolzl font-light">
                       {{sizeShortener(option.name)}}
                       <div class="ml-3 w-2 h-2 rounded-full bg-white"></div>
                      </div>
                     <div v-else class="w-full flex flex-row justify-center items-center font-stolzl font-light">
                       {{sizeShortener(option.name)}}
                       <div class="ml-3 w-2 h-2"></div>
                      </div>
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-5 w-5 text-white group-hover:text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                  </svg>
                </div>
                <div v-if="isFetching" class="animate-pulse transition duration-500 ease-in-out text-white font-light font-stolzl relative flex flex-row justify-center items-center w-1/3 bg-coolGray-900 h-16 mr-2 cursor-pointer border-2 border-coolGray-900">{{text.loading2}}</div>
                <div class="transition duration-500 ease-in-out w-2/3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2" @click="addToCart()">
                  <p id="add" v-if="commerce?.product?.variants[selectedSizeIndex]?.quantityAvailable > 0 && !addingItem">{{text.addToCart}}</p>
                  <p v-else-if="addingItem">{{text.productAdded}}</p>
                  <p v-else>{{text.notify}}</p>
                </div>
              </div>

            <div v-if="!notify">
            <router-link :to="{ name: 'Categories', params: { slug: cms.item.category.slug } }">
              <div class="font-stolzl flex flex-row justify-start items-center mt-5 -ml-1 group">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm mt-5 text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.backTo}} {{cms.item.category.name}}</div>
              </div>
            </router-link>
            </div>
            <div v-else>
              <div class="font-stolzl flex flex-row justify-start items-center mt-5 -ml-1 group" @click="changeNotify()">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm mt-5 text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back}}</div>
              </div>
            </div>
            </div>
            <!-- BUY BUTTON DESKTOP-->

          </div>
          </div>
        </div>
      </div>
      <!-- PRODUCT INFO-->
      <!-- BOTTOM // RIGHT -->

    </div>

    <!-- MORE-->
    <div v-if="cms?.item && cmsImages?.item"  id="more" :class="{'hidden' : zoomedIn}">
      <!-- FULL WIDTH IMAGE-->
      <div v-if="cmsImages.item.fullscreenImage" :ref="setRef" data-anim="0" class=" font-stolzl relative w-screen h-screen mb-2 px-2 opacity-0">
        <div v-if="cmsImages.item.fullscreenImageCredits" class="absolute z-20 bottom-4 md:bottom-12 left-6 md:left-12 w-auto h-auto overflow-hidden">
         <div :ref="setRef" data-anim="0" class="opacity-0 w-full md:w-1/2 break-words leading-snug font-light text-base md:text-sm text-coolGray-50 md:whitespace-pre">{{cmsImages.item.fullscreenImageCredits}}</div>
        </div>
        <div class=" h-screen bg-local bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + cmsImages.item.fullscreenImage.url + ')' }"></div>
      </div>
      <!-- FULL WIDTH IMAGE-->

      <!-- INFO HEADING-->
      <div v-if="cms.item.moreHeading" class="w-auto h-auto overflow-hidden px-4 md:px-8 my-10 md:my-40">
        <div class="w-full  opacity-0" :ref="setRef" data-anim="0">
        <div class="w-full md:w-5/6 text-2xl md:text-5xl font-light text-coolGray-900 md:leading-snug">{{cms.item.moreHeading}}</div>
      </div>
      </div>

      <!-- INFO HEADING-->

      <!-- INFO 01-->
      <div v-if="cms.item.more01Heading && cmsImages.item.more01Image" class="h-auto w-full grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-8 mt-10 md:mt-32">
        <div class="w-full h-75 bg-coolGray-100 opacity-0"  :ref="setRef" data-anim="0">
          <div v-if="cmsImages.item.more01Image" class="relative w-full h-full bg-local bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + cmsImages.item.more01Image.url + ')' }">
            <div class="absolute bottom-5 left-5 w-auto h-auto overflow-hidden">
              <div :ref="setRef" data-anim="0" class="opacity-0  w-1/2 leading-snug font-light text-base md:text-sm text-coolGray-50 whitespace-pre">{{cmsImages.item.more01ImageCredits}}</div>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col justify-start md:pl-8">
          <div class="w-auto h-auto overflow-hidden">
            <div :ref="setRef" data-anim="0" class="font-light text-2xl md:text-5xl text-coolGray-900 opacity-0">{{cms.item.more01Heading}}</div>
          </div>
          <div class="w-auto h-auto overflow-hidden">
            <div :ref="setRef" data-anim="0" class="font-light text-base text-coolGray-900 mt-5 w-full md:w-3/4 opacity-0">{{cms.item.more01Text}}</div>
          </div>
        </div>
      </div>
      <!-- INFO 01-->

      <!-- INFO 02-->
      <div v-if="cms.item.more02Heading && cmsImages.item.more02Image" class="h-auto w-full grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-8 mt-10 md:mt-44">
        <div class="w-full flex flex-col justify-start md:pl-8 order-2 md:order-1">
          <div class="w-auto h-auto overflow-hidden">
            <div :ref="setRef" data-anim="0" class="font-light text-xl md:text-5xl text-coolGray-900 opacity-0">{{cms.item.more02Heading}}</div>
          </div>
          <div class="w-auto h-auto overflow-hidden">
            <div :ref="setRef" data-anim="0" class="font-light text-base text-coolGray-900 mt-5 w-full md:w-3/4 opacity-0">{{cms.item.more02Text}}</div>
          </div>
        </div>
        <div class="w-full h-75 bg-coolGray-100 opacity-0 order-1 md:order-2"  :ref="setRef" data-anim="0">
          <div v-if="cmsImages.item.more01Image" class="relative w-full h-full bg-local bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + cmsImages.item.more02Image.url + ')' }">
            <div class="absolute bottom-5 left-5 w-auto h-auto overflow-hidden">
              <div :ref="setRef" data-anim="0" class="opacity-0  w-1/2 leading-snug font-light text-base md:text-sm text-coolGray-50 whitespace-pre">{{cmsImages.item.more02ImageCredits}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- INFO 02-->

      <!-- RELATED-->
      <div v-if="cms?.item?.related?.length > 0 && cmsImages?.item" class="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-8 mt-8">
        <div v-for="(item,index) in cms.item.related" :ref="setRef" data-anim="0" class="h-66 w-full cursor-pointer opacity-0">

            <div class="relative bg-coolGray-100 h-full w-full flex flex-row justify-center items-center group overflow-hidden" @click="goToProduct(item.id, item.slug)">
              <div v-if="cmsImages?.item?.related[index]?.images[0]" class="transition duration-300 ease-in-out w-5/6 md:w-4/6 h-full transform-gpu md:group-hover:scale-105 bg-local bg-center bg-no-repeat bg-contain" :style="{ backgroundImage: 'url(' + cmsImages.item.related[index].images[0].url + ')' }"></div>
              <div  class="absolute top-0  mt-4 md:mt-8 pl-5 md:pl-10 w-full flex flex-row flex-wrap justify-start items-center">
                <div class="bg-coolGray-900 text-coolGray-50 mr-1 mb-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{text.related}}</div>
                <div v-if="item?.tags?.length > 0" v-for="tag in item.tags">
                  <div class="bg-coolGray-900 text-coolGray-50 mr-1 mb-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{formatTag(tag)}}</div>
                </div>
              </div>
              <div class="w-full h-auto overflow-hidden absolute bottom-0 mb-5">
                <div :ref="setRef" data-anim="0" class="w-full flex flex-col md:flex-row justify-start md:justify-between items-start md:items-end px-4 md:px-8 opacity-0">
                  <div>
                    <div class="font-medium text-lg text-coolGray-900">{{item.name}}</div>
                    <div class="text-sm text-coolGray-700 font-light">{{item.subtext}}</div>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
      <!-- RELATED-->

    </div>
    <!-- MORE-->
    </div>

      <!-- COMPONENTS-->
      <div id="footerWrapper" class="mt-10" :class="{'hidden' : zoomedIn}">
        <Footer />
      </div>
      <getProductQuery />
     <getProductImagesQuery />
     <getCommerceDataQuery />
  </div>

</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import Footer from '../components/footer.vue';
import getProductQuery from '../graphql/graphcms/getProductQuery.vue';
import getProductImagesQuery from '../graphql/graphcms/getProductImagesQuery.vue';
import getCommerceDataQuery from '../graphql/saleor/getCommerceDataQuery.vue';

import scrollToTop from '../mixins/scrollToTop.vue';
import formatPrice from '../mixins/formatPrice.vue';
import showHide from '../mixins/showHide.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Products',
  props: ['productId', 'slug'],
  components: {
    Footer, getProductQuery, getProductImagesQuery, getCommerceDataQuery,
  },
  mixins: [formatPrice, showHide, scrollToTop, intersectionObserver],

  computed: {
    // graphql
    seo() {
      return this.$store.state.data.seo;
    },
    isFetching() {
      return this.$store.state.data.isFetching;
    },
    cms() {
      return this.$store.state.data.result;
    },
    cmsImages() {
      return this.$store.state.data.productImages;
    },
    commerce() {
      return this.$store.state.data.result2;
    },
    // data
    text() {
      return this.$store.state.lang.product[this.$store.state.lang.currentLang];
    },
    addingItem() {
      return this.$store.state.data.addingItem;
    },
    vars() {
      return this.$store.state.data.vars;
    },
    images() {
      if (this.selectedColor && this.colors.length > 0) {
        const filteredImages = [];
        for (let i = 0; i < this.$store.state.data.productImages.item.images.length; i++) {
          const fileName = this.$store.state.data.productImages.item.images[i].fileName.toLowerCase();
          if (fileName.includes(this.selectedColor.toLowerCase())) {
            filteredImages.push(this.$store.state.data.productImages.item.images[i]);
          }
        }
        return filteredImages;
      }
      return this.$store.state.data.productImages.item.images;
    },

    meta() {
      return this.commerce.product.variants[this.selectedSizeIndex].metadata;
    },
    selectedColor() {
      console.log(this.selectedColor);
      return this.$store.state.data.selectedColor;
    },
    selectedSizeIndex() {
      return this.$store.state.data.selectedSizeIndex;
    },
    filteredSelectedSizeIndex() {
      return this.$store.state.data.filteredSelectedSizeIndex;
    },
    selectableVariants() {
      if (this?.commerce?.product) {
        if (this.colors.length > 0 && this.selectedColor) {
          const result = this.commerce.product.variants.filter((x) => x.name.includes(this.selectedColor));
          return result;
        }
        return this.commerce.product.variants;
      }
    },
    colors() {
      const colors = [];
      if (this?.commerce?.product) {
        for (let i = 0; i < this.commerce.product.variants.length; i++) {
          for (let ii = 0; ii < this.commerce.product.variants[i].attributes.length; ii++) {
            if (this.commerce.product.variants[i].attributes[ii].attribute.name == 'color') {
              if (this.commerce.product.variants[i].attributes[ii].values.length > 0) {
                const value = this.commerce.product.variants[i].attributes[ii].values[0].name;
                if (!colors.includes(value)) {
                  colors.push(value);
                }
              }
            }
          }
        }
      }
      if (colors.length > 0) {
        this.$store.commit('setSelectedColor', colors[0]);
      }
      return colors;
    },
    colorCodes() {
      return this.$store.state.data.colorCodes;
    },
    channel() {
      return this.$store.state.data.channel;
    },
    notify() {
      return this.$store.state.data.notify;
    },

    submitted() {
      return this.$store.state.data.submitted;
    },
  },

  watch: {
    seo() {
      document.title = this.seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', this.seo?.metaDescription);
    },
    $route(to, from) {
      if (to.name == from.name) {
        this.$store.commit('setProduct', to.params.slug);
      }
    },
  },

  data() {
    return {
      errors: [],
      parallax: null,
      items: [],
      currentIndex: 0,
      name: null,
      email: null,
      selectingSize: false,
      zoomedIn: false,
      zoomedInMobile: false,
      lastElementHeight: 0,
      selectedText: 1,
      currentImageIndex: 0,
      movePosition: '100',
      activeDragDirection: '',
      screenWidth: document.documentElement.clientWidth,
      dragItem: '',
      currentX: '',
      currentY: '',
      initialX: '',
      initialY: '',
      endX: '',
    };
  },

  mounted() {
    this.$store.commit('setPosition', 'product');
    this.$store.commit('setProduct', this.slug);
    this.$store.commit('setSelectedSizeIndex', 0);
    this.$store.commit('setFilteredSelectedSizeIndex', 0);
  },

  beforeUnmount() {
    this.$store.commit('setNotify', false);
  },

  methods: {

    // ----------------------------------------------//
    // ------------------ANIMATIONS------------------//
    // ----------------------------------------------//

    addingToCart() {
      const el = document.getElementById('add');
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [0, 1],
      });
    },

    fadeInGrowEnter(el, done) {
      const last = `${this.lastElementHeight}px`;
      const current = `${el.offsetHeight}px`;
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [0, 1],
        height: [last, current],
        complete: done,
      });
    },

    fadeInGrowLeave(el, done) {
      this.lastElementHeight = el.offsetHeight;
      anime({
        targets: el,
        easing: 'linear',
        duration: 0,
        opacity: 0,
        complete: done,
      });
    },

    zoomIn() {
      const imageContainer = document.getElementById('imageContainer');
      const infoContainer = document.getElementById('infoContainer');
      const nav = document.getElementById('nav');
      const mobileBar = document.getElementById('mobileBar');
      const space = document.getElementById('space');
      const buttons = document.getElementById('imageButtons');
      const more = document.getElementById('more');
      const footer = document.getElementById('footerWrapper');

      if (!this.zoomedIn) {
        this.zoomedIn = true;
        var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 350,
        });

        if (window.innerWidth < 768) {
          this.zoomedInMobile = true;
          tl
            .add({
              targets: [infoContainer, mobileBar, more, footer],
              opacity: [1, 0],
            })
            .add({
              targets: imageContainer,
              height: ['55vh', '100vh'],
            }, 350);
        } else {
          tl
            .add({
              targets: [infoContainer, nav, more, footer],
              opacity: [1, 0],
            })
            .add({
              targets: space,
              height: ['4rem', '0rem'],
            }, 350)
            .add({
              targets: infoContainer,
              width: ['100%', '0vw'],
            }, 350)
            .add({
              targets: imageContainer,
              width: ['100%', '100vw'],
            }, 350)
            .add({
              targets: nav,
              translateY: -500,
            }, 350)
            .add({
              targets: buttons,
              opacity: [0, 1],
            }, 700);
        }
      } else {
        this.zoomedIn = false;
        var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 350,
        });

        if (window.innerWidth < 768) {
          this.zoomedInMobile = false;
          tl
            .add({
              targets: [mobileBar, more, footer],
              opacity: [0, 1],
            }, 350)
            .add({
              targets: imageContainer,
              height: ['100vh', '55vh'],
            }, 0);
        } else {
          tl
            .add({
              targets: [infoContainer, nav, more, footer],
              opacity: [0, 1],
            }, 350)
            .add({
              targets: infoContainer,
              width: ['0vw', '50vw'],
            }, 0)
            .add({
              targets: space,
              height: ['0rem', '4rem'],
            }, 0)
            .add({
              targets: imageContainer,
              width: ['100vw', '50vw'],
            }, 0)
            .add({
              targets: nav,
              translateY: 0,
            }, 0)
            .add({
              targets: buttons,
              opacity: [1, 0],
            }, 0);
        }
      }
    },

    // ----------------------------------------------//
    // ----------------TOUCH ACTIONS-----------------//
    // ----------------------------------------------//

    dragStart(e) {
      this.dragItem = e.srcElement.closest('#slider');

      if (e.type === 'touchstart') {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
      }
    },

    drag(e) {
      if (e.type === 'touchmove') {
        this.currentX = e.touches[0].clientX - this.initialX;
        this.currentY = e.touches[0].clientY - this.initialY;
        this.endX = e.touches[0].clientX;
      }

      const distance = Math.abs(this.currentX) - Math.abs(this.currentY);

      if (Math.abs(distance) > 10 && this.activeDragDirection == '') {
        if (Math.abs(this.currentX) > Math.abs(this.currentY)) {
          this.activeDragDirection = 'horizontal';
        } else {
          this.activeDragDirection = 'vertical';
        }
      }

      if (this.activeDragDirection == 'horizontal') {
        // e.preventDefault();

        const moving = this.currentX + this.screenWidth * (this.currentImageIndex * -1);
        anime.set(this.dragItem, {
          translateX: moving,
        });
      }
    },

    dragEnd(e) {
      const that = this;
      if (this.activeDragDirection == 'horizontal') {
        if (this.initialX > this.endX) {
        // swipe left
          if (this.currentImageIndex == this.images.length - 1) {
          // bounce back
            this.movePosition = this.currentImageIndex * this.screenWidth;
            anime({
              targets: this.dragItem,
              easing: 'easeInOutCubic',
              duration: 250,
              translateX: `-${this.movePosition}`,
            });
          } else {
          // move
            this.currentImageIndex++;
            this.movePosition = this.currentImageIndex * this.screenWidth;
            anime({
              targets: this.dragItem,
              easing: 'easeOutSine',
              duration: 100,
              translateX: `-${this.movePosition}`,
            });
          }
        } else {
        // swipe right
          if (this.currentImageIndex == 0) {
          // bounce back
            anime({
              targets: this.dragItem,
              easing: 'easeInOutCubic',
              duration: 250,
              translateX: 0,
            });
          } else {
          // move
            this.currentImageIndex--;
            this.movePosition = this.currentImageIndex * this.screenWidth;
            anime({
              targets: this.dragItem,
              easing: 'easeOutSine',
              duration: 100,
              translateX: `-${this.movePosition}`,
            });
          }
        }
      }
      this.activeDragDirection = '';
      this.currentX = 0;
    },

    // ----------------------------------------------//
    // ------------------METHODS---------------------//
    // ----------------------------------------------//

    formatTag(tag) {
      return tag.replace(/_/g, ' ').toUpperCase();
    },

    changeNotify() {
      this.$store.commit('setNotify', !this.notify);
      this.$store.commit('setSubmitted', false);
    },

    sizeShortener(size) {
      let short = size.replace(this.selectedColor, '');
      short = short.replace('/', '');
      short = short.trim();
      return short;
    },

    goToProduct(id, slug) {
      const that = this;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        that.$router.push({ name: 'Products', params: { productId: id, slug } });
      }, 350);
    },

    submit() {
      this.$store.commit('setSubmitted', true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: this.name, email: this.email, product: this.cms.item.name, variant: this.commerce.product.variants[this.selectedSizeIndex].name,
        }),
      };
      fetch('https://hook.integromat.com/jd0lo11z5kav4u2gb453awygdyrq4bln', requestOptions);
    },

    setIndex(variantName) {
      const index = this.commerce.product.variants.findIndex((x) => x.name == variantName);
      const filteredIndex = this.selectableVariants.findIndex((x) => x.name == variantName);
      this.$store.commit('setSelectedSizeIndex', index);
      this.$store.commit('setFilteredSelectedSizeIndex', filteredIndex);
    },

    addToCart() {
      if (this.commerce?.product?.variants[this.selectedSizeIndex]?.quantityAvailable > 0) {
        const variantSizeIndex = this.selectedSizeIndex;
        const productName = this.commerce.product.name;
        const variantID = this.commerce.product.variants[variantSizeIndex].id;
        const variantName = this.commerce.product.variants[variantSizeIndex].name;
        const { slug } = this.commerce.product;
        const price = this.commerce.product.variants[variantSizeIndex].pricing.price.gross.amount;
        const { channel } = this;
        const { url } = this.images[0];
        const meta = this.commerce.product.variants[variantSizeIndex].metadata;
        this.$store.commit('addItem', {
          name: productName, url, id: variantID, slug, variantName, price, channel, meta,
        });
        this.$store.commit('addingItem', true);
        this.addingToCart();
      } else {
        this.$store.commit('setNotify', true);
      }
    },

    down() {
      const bodyRect = document.body.getBoundingClientRect();
      const scrollPos = document.documentElement.scrollTop;
      let goalEl = null;
      let helpPos = 0;
      const images = document.getElementById('slider').getElementsByTagName('div');

      for (const image of images) {
        const rect = image.getBoundingClientRect();
        const elPos = rect.top - bodyRect.top;

        if (elPos > scrollPos) {
          if (helpPos == 0 && (elPos - scrollPos) > 10) {
            helpPos = elPos;
            goalEl = image;
          }
        }
      }

      if (goalEl) {
        goalEl.scrollIntoView({ behavior: 'smooth' });
      }
    },

    up() {
      const bodyRect = document.body.getBoundingClientRect();
      const scrollPos = document.documentElement.scrollTop;
      let goalEl = null;
      const images = document.getElementById('slider').getElementsByTagName('div');

      for (const image of images) {
        const rect = image.getBoundingClientRect();
        const elPos = rect.top - bodyRect.top;
        if (elPos < scrollPos) {
          goalEl = image;
        }
      }

      if (goalEl) {
        goalEl.scrollIntoView({ behavior: 'smooth' });
      }
    },

    switchColor(color) {
      console.log(color);
      this.$store.commit('setSelectedColor', color);
      if (window.innerWidth < 768) {
        this.currentImageIndex = 0;
        this.movePosition = this.currentImageIndex * this.screenWidth;
        anime({
          targets: this.dragItem,
          easing: 'easeOutSine',
          duration: 100,
          translateX: `-${this.movePosition}`,
        });
      } else {
        window.scrollTo({ top: 0 });
      }
      const index = this.commerce.product.variants.findIndex((x) => x.name == this.selectableVariants[0].name);
      this.$store.commit('setSelectedSizeIndex', index);
      this.$store.commit('setFilteredSelectedSizeIndex', 0);
    },

  },

};
</script>

<style>
  .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}
</style>
