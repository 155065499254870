<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name:"showHide",
  methods:{
    
    showInfo(el, done){
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 350,
        opacity: [0,1],
        complete: done
      });
    },
    
    hideInfo(el, done){
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 350,
        opacity: [1,0],
        complete: done
      });
    },

  }
 }
</script>