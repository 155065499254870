<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name:"intersectionObserver",
  
  data(){
    return {
      observer: null,
    }
  },
  
  mounted(){
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        threshold: 0.5,
      }
    );
  },
  
   beforeUnmount(){
    this.observer.disconnect();
    this.observer = null
  },
  
  methods:{
    
    setRef(el){
      if(el && this.observer){
        this.observer.observe(el);
      }
    },  
    
    onElementObserved(entries) {
      var that = this;
      var round = 0;
      entries.forEach(function(entry){
        if(entry.isIntersecting){
          if(entry.target.getAttribute("data-anim") == '0'){
            entry.target.setAttribute('data-anim', '1');
            that.observer.unobserve(entry.target);
            const delay = round++ * 80;
            that.showElement(entry.target, delay);
          } else {
            that.observer.unobserve(entry.target);
          }
        }
      });
    },
    
    showElement(el, delay){
      anime({
            targets: el,
            easing: 'easeOutCirc',
            duration: 1000,
            delay: delay,
            opacity: [0,1],
            translateY: [150, 0]
          });
    },
  }
 }
</script>