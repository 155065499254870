<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getProductQuery",
  
  watch: {
    result() {
      this.$store.commit('graphqlData', { item: 'result', result: this.result });
      this.$store.commit('graphqlData', { item: 'seo', result: this.result.item.seo });
     },
  },
    
  setup() {
    const store = useStore();
    
    const variables = reactive({
      slug: computed(() => store.state.data.productSlug),
      channel: computed(() => store.state.data.channel),
      lang: computed(() => store.state.lang.currentLang)
    });
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
      cachePolicy: 'network-only',
    });
    
    const getProductQuery = gql`
      query MyQuery ($slug: String!, $lang:Locale!) {
          item(where: {slug: $slug}, locales: [$lang]) {
            name
            subtext
            category {
              slug
              name
            }
            info1 {
              html
              markdown
            }
            info2 {
              html
              markdown
            }
            info3 {
              html
              markdown
            }
            seo {
              metaDescription
              metaTitle
            }
            info1Heading
            info2Heading
            info3Heading
            trees
            moreHeading
            more01Heading
            more01Text
            more02Heading
            more02Text
            related {
              name
              slug
              tags
              subtext
              category {
                slug
                name
              }
            }
          }
        }
    `;
    
    const { data: result } = useQuery({
      query: getProductQuery,
      variables
    });
    
    return { result };
  },
}
</script>