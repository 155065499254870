<script>
export default {
  name:"scrollToTop",
  
  mounted(){
    setTimeout(function() {
      window.scrollTo({top: 0});
    }, 250);
  },
  
 }
</script>